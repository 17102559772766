<template>
  <div v-if="!isValidated"
       class="bg-[linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url('/images/protected-bg.jpg')] bg-cover bg-no-repeat">
    <TsLevel class="max-w-screen-xl mx-auto px-6">
      <TsLevelRight>
        <TsLevelItem>
          <TsGrid class="min-h-screen place-items-center flex-col-reverse">
            <TsForm dataTestid="protected"
                    appendClass="bg-natural-soft-white min-w-[280px] md:min-w-[420px] max-w-md rounded-lg border py-8 shadow-lg"
                    :submitBtnLabel="isLoading ? 'Loading...' : signupButtonLabel"
                    @submit="handleRequest"
                    formTitle="Dev Signup"
                    :submitBtnDisabled="isLoading || (signupButtonLabel === 'Submit' && signupForm.otp.length !== 6)"
            >
              <template #form-header>

                <div class=" mb-3 -ml-0.5 text-left">
                  <NuxtImg class="w-28 mr-0.5 inline"
                           style="vertical-align:middle" src="/images/logos/toolstation-logo-halo.svg" loading="lazy"/>
                  <TsVersion />
                </div>


                <TsTypography variant="h2">Website is Protected</TsTypography>
              </template>
              <template #form-body>
                <div class="">
                  <TsLabel label="Enter email address to gain access"
                           :message="formValidationMessage.email"
                           :variant="formFieldsVariant.email"
                  >
                    <TsInput v-model="signupForm.email"
                             type="email"
                             placeholder="Enter email address"
                             size="sm"
                             :variant="formFieldsVariant.email"
                    />
                  </TsLabel>
                </div>

                <div class="" :class="[
                      isOtpHidden && 'hidden'
                  ]">
                  <TsLabel label="Enter OTP"
                           :variant="formFieldsVariant.otp"
                  >
                    <TsOtpInput v-model="signupForm.otp"
                                :loading="false"
                                :message="formValidationMessage.otp"
                                :variant="formFieldsVariant.otp"
                    />
                  </TsLabel>
                </div>

              </template>
            </TsForm>
          </TsGrid>
        </TsLevelItem>
      </TsLevelRight>
    </TsLevel>
  </div>
</template>

<script setup lang="ts">
import TsVersion from "~/components/atoms/TsVersion.vue";

definePageMeta({
  layout: 'none',
})

useHead({
  title: 'Toolstation | Protected'
})

const config = useRuntimeConfig();
const isValidated = ref(true);
const isLoading = ref(false);

const clientDetails = reactive({
  ip: '',
  userAgent: '',
  os: '',
  browser: '',
  isMobile: ''
})

const getUserIp = async () => {
  const {data} = await useFetch('https://api.ipify.org/');
  return data.value;
}

if (process.client) {
  clientDetails.userAgent = window.navigator.userAgent;
  if(window.navigator.userAgentData){
    clientDetails.browser = window.navigator.userAgentData.brands[0].brand;
    clientDetails.isMobile = window.navigator.userAgentData.mobile;
    clientDetails.os = window.navigator.userAgentData.platform;
  }
}

clientDetails.ip = await getUserIp();

onMounted(async () => {
  const accessToken = useCookie('TOKEN_EU24WEB_DEV');

  if (!config.public.mockApiBaseUrl) {
    return navigateTo('/');
  }

  if (accessToken.value) {
    // token validation body
    const body = {
      _token: accessToken.value
    }

    // token validation
    const isTokenValid = await $fetch('/api/token-validate', {
      method: "POST",
      body
    })

    if (isTokenValid.success) {
      return navigateTo('/');
    }
  }

  isValidated.value = false;
})

// Singup Form data
const signupForm = ref<Record<string, string>>({
  email: '',
  otp: ''
})

// Form Validation Messages
const formValidationMessage = ref<Record<string, string>>({
  email: '',
  otp: ''
})

// Form Fields Variants
const formFieldsVariant = ref<Record<string, string>>({
  email: '',
  otp: ''
})

// Otp hidden or visible State
const isOtpHidden = ref(true);

// Singup Button & Api Response
const signupButtonLabel = ref('Send OTP');
const response: any = ref({});

// To Get OTP
const handleSendOtp = async () => {
  const body = {
    email: signupForm.value.email,
    clientDetails: clientDetails
  }

  let options = {
    method: 'POST',
    params:body,
    show_notifications:false
  }

  let res = await useAjax('/api/otp/send',options);

  response.value = res;

}

// On Form Submit
const handleSubmit = async () => {
  const body = {
    email: signupForm.value.email,
    otp: signupForm.value.otp
  }


  let options = {
    method: 'POST',
    params:body,
    show_notifications:false
  }

  let data = await useAjax('/api/otp/validate',options);

  if (data && data.success) {
    // Get current date
    let currentDate = new Date();

    // Calculate 7 days from now
    let expirationDate = new Date(currentDate.getTime() + (7 * 24 * 60 * 60 * 1000));

    const authToken = useCookie('TOKEN_EU24WEB_DEV', {
      maxAge: (expirationDate.getTime() - currentDate.getTime()) / 1000
    });

    const authTokenDate = useCookie('TOKEN_VALIDATION_LAST_DATE_EU24WEB_DEV', {
      maxAge: (expirationDate.getTime() - currentDate.getTime()) / 1000
    });

    authToken.value = data.token;
    authTokenDate.value = currentDate;
  }

  response.value = data;
}

const handleRequest = async () => {
  if (signupButtonLabel.value === "Send OTP") {
    isLoading.value = true;

    await handleSendOtp();

    if (response.value.success) {
      isOtpHidden.value = false;
      signupButtonLabel.value = "Submit";
      formValidationMessage.value.email = response.value.messages[0];
      formFieldsVariant.value.email = 'success';
    } else {
      formValidationMessage.value.email = response.value.errors[0];
      formFieldsVariant.value.email = 'danger';
    }

    isLoading.value = false;
  } else if (signupButtonLabel.value === "Submit") {

    formValidationMessage.value.otp = 'Verifying OTP...'

    await handleSubmit();

    if (response.value.success) {
      formFieldsVariant.value.otp = 'success';
      formValidationMessage.value.otp = response.value.messages[0];

      setTimeout(() => {
        navigateTo('/')
      }, 1000)
    } else {
      formFieldsVariant.value.otp = 'danger';
      formValidationMessage.value.otp = response.value.errors[0];
    }

  }
}
</script>
